<template>
  <viewcard--c title="Relatórios" :btnew="btnew">
    <!-- list items -->
    <view--c permission="permission.reports.view" :busy="isloading">
      <Table
        :list="!isloading ? list[currentePage].itens : []"
        @rowClick="onClickSelected"
        :fields="fields"
        responsive
        border="full"
      >
        <template #endpoint="data">
          <b-avatar
            :variant="data.item.endpoint ? 'success' : 'danger'"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.endpoint ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </template>

        <template #secret_key="data">
          <b-avatar
            :variant="data.item.secret_key ? 'success' : 'danger'"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.secret_key ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </template>
      </Table>

      <!-- paginação -->
      <b-pagination
        v-model="currentePage"
        @change="getLoadMore"
        :total-rows="rows"
        v-if="rows > 20"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </view--c>
  </viewcard--c>
</template>

<script>
import Table from "@/components/Table.vue";
import _iframeService from "@/services/Iframe-permission-service";
import _permissionsService from "@/services/group-permissions";

export default {
  components: {
    Table
  },
  data() {
    return {
      btnew: {
        permission: "permission.reports.view",
        to: "/registrations/reports/0",
      },
      isloading: false,
      currentePage: 1,
      size: 12,
      rows: 20,
      fields: [
        { key: "ds_permission", label: "Nome" },
        { key: "endpoint", label: "URL" },
        { key: "secret_key", label: "Chave Secreta" },
      ],
      listPermissions: [],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
    };
  },
  created() {
    this.getAllPermissions();
    this.getRecords(this.currentePage);
  },
  methods: {
    dsPermission(code) {
      return this.listPermissions.filter((f) => f.value === code)[0].label;
    },
    getRecords(_page) {
      this.isloading = true;
      _iframeService
        .show(_page)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              this.rows += this.$utils.pagination(res.content, this.size);
              this.currentePage = _page;
            }
          }
          this.isloading = false
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    getAllPermissions() {
      this.isloading = true;
      _permissionsService
        .showPermissions()
        .then((res) => {
          this.listPermissions = res;
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    onClickSelected(record, _) {
      this.$router.push({
        path: `/registrations/reports/${record.id}`,
      });
    },
  },
};
</script>